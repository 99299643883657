import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/(landing)": [~16],
		"/(app)/explore": [~2],
		"/(app)/home": [~3],
		"/(learn)/learn": [~20],
		"/(app)/learn/enrollments": [~4],
		"/(app)/learn/workshops/[id]": [~5],
		"/(landing)/login": [17],
		"/(auth)/login/success": [~13],
		"/(auth)/logout": [~14],
		"/(app)/onboarding/process": [~6],
		"/(app)/onboarding/professions": [7],
		"/(app)/onboarding/profile": [~8],
		"/(shared)/posts/[answerId]/edit": [~22],
		"/(shared)/posts/[user_name]/[slug]": [~23],
		"/(landing)/privacy": [18],
		"/(app)/questions/[id]": [~9],
		"/(storytelling-for-founders)/storytelling-for-founders": [27],
		"/(storytelling-for-founders)/storytelling-for-founders/pay": [~28],
		"/(storytelling)/storytelling": [24],
		"/(storytelling)/storytelling/pay": [~25],
		"/(storytelling)/storytelling/storytelling": [~26],
		"/(landing)/terms": [19],
		"/(auth)/verify": [~15],
		"/(app)/write": [~10],
		"/(app)/write/answer": [~11],
		"/(app)/write/attach": [~12],
		"/(shared)/[user_name]": [~21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';